export default [
  {
    type: "inline",
    properties: [
      {
        name: "margin-top",
        label: "Top",
        desc: "Sets the top margin of the element.",
        type: "unit",
      },
      {
        name: "margin-bottom",
        label: "Bottom",
        desc: "Sets the bottom margin of the element.",
        type: "unit",
      },
      {
        name: "margin-left",
        label: "Left",
        desc: "Sets the left margin of the element.",
        type: "unit",
      },
      {
        name: "margin-right",
        label: "Right",
        desc: "Sets the right margin of the element.",
        type: "unit",
      },
    ],
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        name: "margin",
        label: "Margin",
        desc: "Sets the margin on all four sides of the element.",
        type: "string",
      },
    ],
  },
]
