import React, {useEffect, useContext, useRef, useCallback, useState} from "react"
import { Button, Tabs } from 'antd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { X } from 'react-feather'
import Draggable from 'react-draggable'
import { Global, css } from '@emotion/core'

const { TabPane } = Tabs;


import 'semantic-ui-css/semantic.min.css'
import "./editor.scss"

import { getPageOrSectionID, getInitialContent } from "../scripts/server/pageContent"

import { EditorContextProvider, EditorContext } from '../Contexts/EditorContext';
import StylePanel from './components/StylePanel'
import ContentPanel from './components/ContentPanel'
import LayersPanel from './components/LayersPanel'
import WebsiteToolbar from './components/WebsiteToolbar'



import Page from './page'
import { PageContextProvider } from "../Contexts/PageContext"

import { containers } from "../../../../src/pb-containers"
import HoverStateController from "./components/HoverStateController";
import EditorTopNav from "./components/EditorTopNav";
import { StylesContextProvider, StylesContext } from "../Contexts/StylesContext";
import { ComponentsContext, ComponentsContextProvider } from "../Contexts/ComponentsContext";
import { SectionsContextProvider, SectionsContext } from "../Contexts/SectionContext";
import { localStore } from "../utils/customStorage";


const Editor = () => {
    const [state, dispatch] = useContext(EditorContext)
    const [componentsState, componentsDistpach] = useContext(ComponentsContext)
    const [stylesState] = useContext(StylesContext)
    const canvasEl = useRef(null)
    
    // Select Element
    const handleClick = useCallback(e => {
        if (state.selected) return

        for (let elem of e.path) {
            if (elem.onclick) {
                elem.onclick()
                break
            }

            if (!elem) continue

            const elemId = elem.getAttribute('data-sb-comp-id')
            if (componentsState[elemId]) {
                console.log("SELECTED ELEMENT", elemId)
                if (elem.id === "wrapper") break
                else if (state.selected !== elemId) {
                    dispatch([ 'SELECT_COMPONENT', elemId ])
                }
                break
            }
        }
    }, [JSON.stringify(componentsState)])


    useEffect(() => {
        if (canvasEl.current) {
            canvasEl.current.addEventListener("click", handleClick)
    
            return () => {
                canvasEl.current.removeEventListener('click', handleClick)
            };
        }
      }, [handleClick]);


    const id = getPageOrSectionID()

      let fakePageContext = {
            id: id, 
            name: state.page.data.name.iv,
            slug: state.page.data.slug ? state.page.data.slug.iv : "",
            components: componentsState,
            page: state.page.data
        }

    const deselect = useCallback(() => {
        dispatch([
            'SELECT_COMPONENT',
            null
        ])
    })

    let PageTemplate = Page
    if (state.page.data.container && state.page.data.container.iv) {
        PageTemplate = containers[state.page.data.container.iv.replace(".js", "")]
    }

    const fakeTemplateProps = {
        location: {
            pathname: ""
        }
    }

    console.log("EDITOR RE-RENDER")

    const wrapper = state.page.data.type && state.page.data.type.iv === "section" ? state.page.data.slug.iv : 'wrapper'

    const canvasWidth = css`
        max-width: ${(state.screenSize === "mobile" && "360px") || (state.screenSize === "tablet" && "768px") || '100%'};
    `

    return (
        <div className="pb">

            <Global styles={stylesState.classes} />
            <HoverStateController canvasEl={canvasEl} />
            <EditorTopNav />

            <div className="pb-main">

                <div className="sb-layers-overlay" draggable="true">
                        <div className="sb-layers-header"><h3>Layers</h3></div>
                        <LayersPanel wrapper={wrapper} />
                </div>

                <div css={canvasWidth} className="pb-website">
                    <div className="pb-website-topbar">
                        <WebsiteToolbar />
                    </div>
                    <div className="pb-canvas" id="canvas" ref={canvasEl}>
                        <PageTemplate canvas={true} selected={state.selected} pageContext={fakePageContext} {...fakeTemplateProps} />
                    </div>
                </div>

                {state.selected && <Draggable handle=".ant-tabs-nav">
                    <div className="pb-options pb-ml-8">
                        <Tabs defaultActiveKey="1" tabBarExtraContent={<Button type="link" onClick={deselect} className="pb-p-0 pb-m-0"><X style={{ fontSize: 18 }} /></Button>}>
                            <TabPane
                                tab="Content"
                                key="1"
                                className="sb-tab-panel">
                                    <ContentPanel />
                            </TabPane>
                            {componentsState[state.selected].type !== "section-component" && <TabPane
                                tab="Style"
                                key="2"
                                className="sb-tab-panel">
                                    <StylePanel />
                            </TabPane>}
                        </Tabs>
                    </div>
                </Draggable>}      

            </div>
        </div>
    )
} 

function AddPageContext() {
    const [state, dispatch] = useContext(EditorContext)
    const [componentsState, componentsDispatch] = useContext(ComponentsContext)
    const [stylesState, stylesDispatch] = useContext(StylesContext)
    const [sections, setSections] = useState([])

    const id = getPageOrSectionID()

    useEffect(() => {
        let asyncFct = async () => {
            const initialContent = await getInitialContent()
            console.log("INITIAL CONTENT", initialContent)
            dispatch([
                'SET_INITIAL_STATE',
                {
                    page: initialContent.page
                }
            ])

            setSections(initialContent.sections)

            if (!localStore.getItem("sb-page-last-modified") || localStore.getItem("sb-page-id") !== id  || localStore.getItem("sb-page-last-modified") < initialContent.pageLastModified) {
                componentsDispatch([
                    'SET_INITIAL_STATE',
                    initialContent.components
                ])
            }

            if (!localStore.getItem("sb-styles-last-modified") || localStore.getItem("sb-styles-id") !== id  || localStore.getItem("sb-styles-last-modified") < initialContent.stylesLastModified) {
                stylesDispatch([
                    'SET_INITIAL_STATE',
                    {
                        styles: initialContent.styles,
                        classes: initialContent.classes,
                    }
                ])
            }
            
        } 
        asyncFct()
    }, [id])

    let fakePageContext = {
        id: id, 
        name: state.page ? state.page.data.name.iv : "",
        slug: state.page.data.slug ? state.page.data.slug.iv : "",
        page: state.page.data,
        ...(state.page.data.pageContext ? state.page.data.pageContext.iv : {})
    }

    return (
        <SectionsContextProvider initialState={sections}>
            <PageContextProvider initialState={fakePageContext}>
                <Editor />  
            </PageContextProvider>
        </SectionsContextProvider>   
    );
}

function App() {
    return (
      <EditorContextProvider>
            <StylesContextProvider>
                <ComponentsContextProvider>
                    <DndProvider backend={HTML5Backend}>
                        <AddPageContext />
                    </DndProvider>
                </ComponentsContextProvider>
            </StylesContextProvider>
      </EditorContextProvider>
    );
}

export default App
