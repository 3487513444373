export default [
  {
    name: "border",
    label: "Border",
    desc:
      "Sets the width, style, and color for all four sides of an element's border.",
    type: "text",
  },
  {
    name: "border-radius",
    label: "Border Radius",
    desc: "Defines the shape of the border corners of an element.",
    type: "text",
  },
  {
    name: "border-top",
    label: "Border Top",
    desc:
      "Sets the width, style, and color for all four sides of an element's border.",
    type: "text",
  },
  {
    name: "border-left",
    label: "Border Left",
    desc:
      "Sets the width, style, and color for all four sides of an element's border.",
    type: "text",
  },
  {
    name: "border-right",
    label: "Border Right",
    desc:
      "Sets the width, style, and color for all four sides of an element's border.",
    type: "text",
  },
  {
    name: "border-bottom",
    label: "Border Bottom",
    desc:
      "Sets the width, style, and color for all four sides of an element's border.",
    type: "text",
  },
]
