import React from "react"
import { Router } from "@reach/router"
import { login, logout, isAuthenticated, getProfile } from "../utils/auth"
import { Link } from "gatsby"
import { css, jsx } from "@emotion/core"

import Editor from "../templates/editor"
import { Button } from "antd"

const Home = ({ user }) => {
  let style = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
  `
  return (
    <div css={style}>
      <a href="/admin/editor?page=b67aad8d-dbb6-457c-8786-85357b83472a">
        <Button size="large" type="primary">
          Open the editor
        </Button>
      </a>
    </div>
  )
}

const Admin = () => {
  console.log("isAuthenticated", isAuthenticated())

  if (!isAuthenticated()) {
    login()
    return <p>Redirecting to login...</p>
  }

  const user = getProfile()

  let logoutStyle = css`
    position: fixed;
    top: 20px;
    right: 160px;
    z-index: 20000;
  `

  return (
    <>
      <nav css={logoutStyle}>
        <a
          href="#logout"
          onClick={e => {
            logout()
            e.preventDefault()
          }}
        >
          <Button size="small">Log out</Button>
        </a>
      </nav>
      <Router>
        <Editor path="/admin/editor" />
        <Home path="/admin" user={user} />
      </Router>
    </>
  )
}

export default Admin
