export default [
  {
    name: "background-color",
    label: "Background Color",
    desc: "	Defines an element's background color.",
    type: "color",
  },
  {
    name: "background-image",
    label: "Background Image",
    desc: "Defines an element's background image.",
    type: "media",
    renderer: value => `url(${value})`,
  },
  {
    name: "background-size",
    label: "Background Size",
    desc: "Specifies the size of the background images.",
    type: "text | select",
    options: ["auto", "cover", "contain"],
  },
  {
    name: "opacity",
    label: "Opacity",
    desc: "Specifies the transparency of an element.",
    type: "text",
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    properties: [
      {
        name: "background",
        label: "Background",
        desc:
          "Defines a variety of background properties within one declaration.",
        type: "string",
      },
      {
        name: "background-repeat",
        label: "Background Repeat",
        desc: "Specify whether/how the background image is tiled.",
        type: "select",
        options: ["repeat", "repeat-x", "repeat-y", "no-repeat"],
      },
      {
        name: "background-position",
        label: "Background Position",
        desc:
          "Defines a variety of background properties within one declaration.",
        type: "string",
      },
      {
        name: "background-attachment",
        label: "Background Attachment",
        desc:
          "Specify whether the background image is fixed in the viewport or scrolls.",
        type: "select",
        options: ["scroll", "fixed"],
      },
      {
        name: "background-clip",
        label: "Background Clip",
        desc: "Specifies the painting area of the background.",
        type: "select",
        options: ["border-box", "padding-box", "content-box"],
      },
      {
        name: "background-origin",
        label: "Background Origin",
        desc: "	Specifies the positioning area of the background images.",
        type: "select",
        options: ["border-box", "padding-box", "content-box"],
      },
    ],
  },
]
