import React, { useContext, useState, useEffect } from "react"
import {
  Form as FormSemtnaic,
  Button,
  Accordion,
  Icon,
  Dropdown,
} from "semantic-ui-react"
import { Form, Input } from "antd"
import { EditorContext, WrapperComponent } from "../../Contexts/EditorContext"
import { css } from "glamor"
import { getComponentsMap } from "../../components"
import { createContent, saveContent } from "../../scripts/server/pageContent"
import { PageContext } from "../../Contexts/PageContext"
import keys from "deeks"
import { AutoComplete } from "antd"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

import { Button as AntButton, Popconfirm } from "antd"
import { QuestionCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { Code } from "./ContentPanelFields/Code"
import { getTranslated, setTranslated } from "../../scripts/langUtils"

const updateComponents = (id, components, section) => {
  section[id] = components[id]
  delete components[id]

  if (section[id].components.length > 0) {
    for (let comp of section[id].components) {
      updateComponents(comp, components, section)
    }
  }
}

const componentToSection = async (id, componentsState, componentsDispatch) => {
  let parentId = componentsState[id].parentId
  let components = { ...componentsState }
  const wrapperId = `wrapper-${id}`
  let section = {
    [wrapperId]: {
      ...WrapperComponent,
      props: {
        id: wrapperId,
      },
      components: [id],
    },
  }

  updateComponents(id, components, section)
  componentsDispatch(["SELECT_COMPONENT", null])

  const newId = `section-${id}`

  const { data } = await createContent("section", wrapperId, wrapperId, section)

  console.log("NEW ID", data)
  componentsDispatch(["ADD_SECTION", data])

  components = {
    ...components,
    [parentId]: {
      ...components[parentId],
      components: components[parentId].components.map(cId =>
        cId === id ? newId : cId
      ),
    },
    [newId]: {
      ...WrapperComponent,
      type: "section-component",
      parentId: parentId,
      props: {
        id: newId,
        section: data.id,
      },
    },
  }

  console.log("AFTER SWITCH", id, parentId, components, section)

  componentsDispatch(["SET_COMPONENTS", components])
  componentsDispatch(["SELECT_COMPONENT", newId])

  await saveContent({
    ...componentsState,
    components,
  })
}

const ContentPanel = () => {
  const [state, dispatch] = useContext(EditorContext)
  const [componentsState, componentsDispatch] = useContext(ComponentsContext)
  const [pageContext] = useContext(PageContext)
  const [currentProps, setCurrentProps] = useState({})
  const [selected, setSelected] = useState(null)
  const [error, setError] = useState(null)
  const [newID, setNewID] = useState(null)
  const [active, setActive] = useState(false)
  const [converting, setConverting] = useState(false)
  const [suggestions, setSuggestions] = useState([])

  useEffect(() => {
    if (selected !== state.selected) {
      setCurrentProps({})
      setSelected(state.selected)
      setNewID(state.selected)
    }
    if (state.selected && componentsState[state.selected].props != currentProps)
      setCurrentProps(componentsState[state.selected].props)
  }, [state.selected, componentsState[state.selected]])

  useEffect(() => {
    setSuggestions(keys.deepKeys(pageContext).map(p => ({ value: `{${p}}` })))
  }, [pageContext])

  if (!state.selected || !componentsState[state.selected]) {
    return <div>Select an element to change it's content.</div>
  }

  const updateProp = e => {
    console.log("Update Prop", e.target.value, e.target.name)
    update(e.target.name, e.target.value, false, true)
  }

  const blurProp = e => {
    console.log("Update Prop", e.target.value, e.target.name)
    update(e.target.name, e.target.value, true, true)
  }

  const updateSelect = (_elem, { name, value }) => {
    console.log("Select", name, value)
    update(name, value)
  }

  const updateRadio = (_elem, { name, checked }) => {
    console.log("UPDATE RADIO", name, checked)
    update(name, checked)
  }

  const updateID = e => {
    console.log("Update ID", e.target.value, e.target.name)

    if (componentsState[newID]) {
      setError("This ID is already used.")
      return
    }

    setError(null)
    componentsDispatch(["UPDATE_ID", { id: state.selected, newID }])
  }

  const update = (name, value, updateComponent = true, useLang = false) => {
    let newProps = {
      ...currentProps,
      [name]: useLang
        ? setTranslated(value, currentProps[name], state.lang)
        : value,
    }
    setCurrentProps(newProps)
    if (updateComponent)
      componentsDispatch([
        "UPDATE_PROPS",
        { id: state.selected, prop: newProps },
      ])
  }

  const convertToSection = async () => {
    setConverting(true)
    await componentToSection(
      state.selected,
      componentsState,
      componentsDispatch
    )
    setConverting(false)
  }

  function deleteComponent() {
    const id = state.selected

    dispatch(["SELECT_COMPONENT", null])

    setTimeout(() => {
      componentsDispatch(["DELETE_COMPONENT", id])
    }, 100)
  }

  let rule = css({
    overflowY: "auto",
    overflowX: "hidden",
  })

  let fields = []
  const compsMap = getComponentsMap()
  let block = compsMap.get(componentsState[state.selected].type)

  const relativeContainerStyle = css({
    position: "relative",
  })

  const floatingPositionStyle = css({
    position: "absolute",
    right: 0,
    top: 0,
  })

  if (block) {
    for (const propDef of block.props) {
      const value = currentProps[propDef.name]
      const valueTranslated = getTranslated(value, state.lang)
      const lang =
        typeof value === "string"
          ? "iv"
          : value && value[state.lang] && value[state.lang].length
          ? state.lang
          : "iv"

      if (propDef.type === "text")
        fields.push(
          <Form.Item label={propDef.label}>
            <Input
              addonAfter={lang}
              name={propDef.name}
              value={valueTranslated || ""}
              onChange={updateProp}
              onBlur={blurProp}
              disabled={propDef.editable || false}
            />
          </Form.Item>
        )
      else if (propDef.type === "context-reference")
        fields.push(
          <FormSemtnaic.Input
            fluid
            name={propDef.name}
            label={propDef.label}
            value={valueTranslated || ""}
            onChange={updateProp}
            onBlur={blurProp}
            size="small"
            disabled={propDef.editable || false}
          />
        )
      else if (propDef.type === "boolean")
        fields.push(
          <div className={relativeContainerStyle}>
            {typeof value === "string" && (
              <AutoComplete
                options={suggestions}
                style={{ width: "100%" }}
                name={propDef.name}
                onBlur={e => update(propDef.name, e.target.value)}
                onChange={value => update(propDef.name, value, false)}
                onSelect={value => update(propDef.name, value)}
                value={typeof value !== "string" ? "" : value || ""}
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            )}

            {typeof value !== "string" && (
              <FormSemtnaic.Radio
                toggle
                name={propDef.name}
                label={propDef.label}
                checked={value || false}
                onChange={updateRadio}
                size="small"
                disabled={propDef.editable || false}
              />
            )}

            <div className={floatingPositionStyle}>
              <Dropdown
                inline
                direction="right"
                trigger={
                  <span>{typeof value === "string" ? "query" : "toggle"}</span>
                }
                onChange={(e, { value }) =>
                  update(propDef.name, value === "query" ? "" : false)
                }
                options={[
                  { key: "t", text: "toggle", value: "toggle" },
                  { key: "q", text: "query", value: "query" },
                ]}
                defaultValue={typeof value === "string" ? "query" : "toggle"}
              />
            </div>
          </div>
        )
      else if (propDef.type === "select")
        fields.push(
          <FormSemtnaic.Select
            toggle
            name={propDef.name}
            label={propDef.label}
            options={propDef.values.map(v => ({ key: v, value: v, text: v }))}
            value={value || ""}
            onChange={updateSelect}
            size="small"
            disabled={propDef.editable || false}
          />
        )
      else if (propDef.type === "page-context")
        fields.push(
          <AutoComplete
            options={suggestions}
            style={{ width: "100%" }}
            name={propDef.name}
            onBlur={e => update(propDef.name, e.target.value)}
            onChange={value => update(propDef.name, value, false)}
            onSelect={value => update(propDef.name, value)}
            value={value || ""}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />
        )
      else if (propDef.type === "custom") {
        const CustomInput = propDef.input
        fields.push(
          <div>
            <CustomInput
              label={propDef.label}
              value={value || ""}
              onChange={value => update(propDef.name, value)}
            />
          </div>
        )
      } else if (propDef.type === "code")
        fields.push(
          <Code
            name={propDef.name}
            label={propDef.label}
            value={value || ""}
            onChange={value => update(propDef.name, value, false, false)}
            onBlur={value => update(propDef.name, value, true, false)}
          />
        )
    }
  }

  return (
    <div {...rule} className="pb-pr-2">
      <Form layout="vertical">
        <FormSemtnaic.Input
          error={error}
          fluid
          name="id"
          label="ID"
          value={newID || ""}
          onBlur={updateID}
          onChange={e => setNewID(e.target.value)}
          size="small"
        />

        {fields}
      </Form>

      <Accordion exclusive={false}>
        <Accordion.Title active={active} onClick={() => setActive(!active)}>
          <Icon name="dropdown" />
          Advanced
        </Accordion.Title>
        <Accordion.Content active={active}>
          {componentsState[state.selected].type !== "section-component" && (
            <Button fluid onClick={convertToSection} loading={converting}>
              Convert to section
            </Button>
          )}

          <FormSemtnaic.Input
            fluid
            name="onClick"
            label="onClick"
            value={currentProps["onClick"] || ""}
            onChange={updateProp}
            onBlur={blurProp}
            size="small"
          />

          <label>Active if</label>
          <AutoComplete
            options={suggestions}
            style={{ width: "100%" }}
            name="activeIf"
            onBlur={e => update("activeIf", e.target.value)}
            onChange={value => update("activeIf", value, false)}
            onSelect={value => update("activeIf", value)}
            value={currentProps["activeIf"] || ""}
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
          />

          <div className="pb-my-2">
            <Popconfirm
              title="Are you sure?"
              onConfirm={deleteComponent}
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
            >
              <AntButton danger className="pb-ml-1" icon={<DeleteOutlined />}>
                Delete Component
              </AntButton>
            </Popconfirm>
          </div>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}

export default ContentPanel
