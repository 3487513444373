export default [
  {
    type: "inline",
    properties: [
      {
        name: "display",
        label: "Display",
        desc: "Specifies how an element is displayed onscreen.",
        type: "select",
        options: [
          "none",
          "inline",
          "block",
          "flex",
          "grid",
          "inline-block",
          "inline-flex",
          "inline-grid",
          "inline-table",
          "table",
          "flow",
          "flow-root",
          "list-item",
          "run-in",
          "table-caption",
          "table-column-group",
          "table-header-group",
          "table-footer-group",
          "table-row-group",
          "table-cell",
          "table-column",
          "table-row",
        ],
      },
      {
        name: "position",
        label: "Position",
        desc: "Specifies how an element is positioned.",
        type: "select",
        options: ["static", "relative", "absolute", "fixed", "sticky"],
      },
    ],
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        type: "inline",
        properties: [
          {
            name: "top",
            label: "top",
            desc:
              "Specify the location of the top edge of the positioned element.",
            type: "unit",
          },
          {
            name: "bottom",
            label: "bottom",
            desc:
              "Specify the location of the bottom edge of the positioned element.",
            type: "unit",
          },
          {
            name: "left",
            label: "left",
            desc:
              "Specify the location of the left edge of the positioned element.",
            type: "unit",
          },
          {
            name: "right",
            label: "right",
            desc:
              "Specify the location of the right edge of the positioned element.",
            type: "unit",
          },
        ],
      },
      {
        type: "inline",
        properties: [
          {
            name: "float",
            label: "float",
            desc: "Specifies whether or not a box should float.",
            type: "select",
            options: ["none", "left", "right"],
          },
          {
            name: "clear",
            label: "clear",
            desc:
              "Specifies the placement of an element in relation to floating elements.",
            type: "unit",
            options: ["none", "left", "right", "both", "auto"],
          },
        ],
      },
      {
        name: "z-index",
        label: "z-index",
        desc: "Specifies a layering or stacking order for positioned elements",
        type: "number",
      },
      {
        name: "overflow",
        label: "overflow",
        desc:
          "Specifies the treatment of content that overflows the element's box.",
        type: "select",
        options: ["visible", "hidden", "scroll", "auto"],
      },
      {
        type: "inline",
        properties: [
          {
            name: "overflow-y",
            label: "overflow-y",
            desc:
              "Specifies how to manage the content when it overflows the height of the element's content area.",
            type: "select",
            options: ["visible", "hidden", "scroll", "auto"],
          },
          {
            name: "overflow-x",
            label: "overflow-x",
            desc:
              "Specifies how to manage the content when it overflows the width of the element's content area.",
            type: "select",
            options: ["visible", "hidden", "scroll", "auto"],
          },
        ],
      },
      {
        name: "resize",
        label: "resize",
        desc: "Specifies whether or not an element is resizable by the user.",
        type: "select",
        options: ["none", "both", "horizontal", "vertical"],
      },
      {
        name: "clip",
        label: "clip",
        desc: "Defines the clipping region.",
        type: "text",
      },
      {
        name: "visibility",
        label: "visibility",
        desc: "Specifies whether or not an element is visible.",
        type: "select",
        options: ["visible", "hidden", "collapse"],
      },
      {
        name: "cursor",
        label: "cursor",
        desc: "Specify the type of cursor.",
        type: "select",
        options: [
          "auto",
          "default",
          "none",
          "context-menu",
          "help",
          "pointer",
          "progress",
          "wait",
          "cell",
          "crosshair",
          "text",
          "vertical-text",
          "alias",
          "copy",
          "move",
          "no-drop",
          "not-allowed",
          "all-scroll",
          "col-resize",
          "row-resize",
          "n-resize",
          "e-resize",
          "s-resize",
          "w-resize",
          "ne-resize",
          "nw-resize",
          "se-resize",
          "sw-resize",
          "ew-resize",
          "ns-resize",
          "nesw-resize",
          "nwse-resize",
          "zoom-in",
          "zoom-out",
          "grab",
          "grabbing",
        ],
      },
      {
        name: "box-shadow",
        label: "box-shadow",
        desc: "Applies one or more drop-shadows to the element's box.",
        type: "text",
      },
      {
        name: "box-sizing",
        label: "box-sizing",
        desc: "Alter the default CSS box model.",
        type: "select",
        options: ["content-box", "padding-box", "border-box"],
      },
      {
        name: "transform",
        label: "transform",
        desc: "",
        type: "text",
      },
    ],
  },
]
