import React, { useContext } from "react"
import { css, jsx } from "@emotion/core"
import { EditorContext } from "../../Contexts/EditorContext"
import { PageContext } from "../../Contexts/PageContext"
import { Button, Space, Select } from "antd"
import {
  MobileOutlined,
  DesktopOutlined,
  TabletOutlined,
  ArrowsAltOutlined,
} from "@ant-design/icons"

const BlockDropzone = ({ parentId, id, index }) => {
  const [state, dispatch] = useContext(EditorContext)
  const [pageState] = useContext(PageContext)

  const changeLang = value => {
    dispatch(["SET_LANG", value])
  }

  const setDesktop = () => {
    dispatch(["SET_SCREEN_SIZE", "desktop"])
  }

  const setTablet = () => {
    dispatch(["SET_SCREEN_SIZE", "tablet"])
  }

  const setMobile = () => {
    dispatch(["SET_SCREEN_SIZE", "mobile"])
  }

  const container = css`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    line-height: 1;
  `

  const iconStyle = css`
    font-size: 20px;
  `

  const active = css`
    color: #1da1f2;
  `

  return (
    <div css={container}>
      <div>
        <b>{pageState.name}</b> <br />
        <small>{pageState.slug}</small>
      </div>
      <Space>
        <MobileOutlined
          css={[iconStyle, state.screenSize === "mobile" && active]}
          onClick={setMobile}
        />
        <TabletOutlined
          css={[iconStyle, state.screenSize === "tablet" && active]}
          onClick={setTablet}
        />
        <DesktopOutlined
          css={[iconStyle, state.screenSize === "desktop" && active]}
          onClick={setDesktop}
        />
      </Space>
      <Space>
        <Button type="link">Editor context</Button>
        <Select
          style={{ width: 80 }}
          placeholder="Language"
          optionFilterProp="children"
          onChange={changeLang}
          value={state.lang}
        >
          <option value="iv">(all)</option>
          <option value="fr-ca">fr-ca</option>
          <option value="en-ca">en-ca</option>
        </Select>

        <Button icon={<ArrowsAltOutlined />}></Button>
      </Space>
    </div>
  )
}

export default BlockDropzone
