// Definitions from https://www.tutorialrepublic.com/css-reference/css3-properties.php
import background from "./styles/background"
import margin from "./styles/margin"
import padding from "./styles/padding"
import font from "./styles/font"
import dimensions from "./styles/dimensions"
import formatting from "./styles/formatting"
import flexbox from "./styles/flexbox"
import border from "./styles/border"

const stylesProperties = [
  {
    name: "Dimensions",
    type: "section",
    active: true,
    border: true,
    properties: dimensions,
  },
  {
    name: "Margins",
    type: "section",
    active: true,
    border: true,
    properties: margin,
  },
  {
    name: "Paddings",
    type: "section",
    active: true,
    border: true,
    properties: padding,
  },
  {
    name: "Formatting",
    type: "section",
    active: false,
    border: true,
    properties: formatting,
  },
  {
    name: "Background",
    type: "section",
    active: false,
    border: true,
    properties: background,
  },
  {
    name: "Font",
    type: "section",
    active: false,
    border: true,
    properties: font,
  },
  {
    name: "Flexbox",
    type: "section",
    active: false,
    border: true,
    properties: flexbox,
  },
  {
    name: "Borders",
    type: "section",
    active: false,
    border: true,
    properties: border,
  },
]

export default stylesProperties
