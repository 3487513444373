import React, { useState, useContext, useEffect, useCallback } from "react"
import { Form as SemanticForm } from "semantic-ui-react"
import {
  Drawer,
  Button,
  List,
  Space,
  Divider,
  Form,
  Input,
  Popover,
} from "antd"
import { createContent, getPages } from "../../scripts/server/pageContent"
import { EditorContext } from "../../Contexts/EditorContext"

const PagesList = ({ create, pages }) => {
  async function duplicatePage(page, values) {
    const { data } = await createContent(
      "page",
      values.name,
      values.slug,
      page.data.components.iv
    )
    window.location = `/admin/editor?page=${data.id}`
  }

  const FormContent = ({ page }) => {
    return (
      <Form
        name="basic"
        layout="horizontal"
        size="small"
        initialValues={{
          name: page.data.name.iv + "-copy",
          slug: page.data.slug.iv + "_copy",
        }}
        onFinish={values => duplicatePage(page, values)}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: "Please input a page name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Slug"
          name="slug"
          rules={[{ required: true, message: "Please input a slug!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Duplicate
          </Button>
        </Form.Item>
      </Form>
    )
  }

  return (
    <div>
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={pages}
        renderItem={s => (
          <List.Item
            key={s.id}
            actions={[
              <Popover
                key="duplicate"
                content={<FormContent page={s} />}
                trigger="click"
                overlayStyle={{ zIndex: 100002 }}
              >
                <Button type="link">Duplicate</Button>
              </Popover>,
              <a key="edit" href={`/admin/editor?page=${s.id}`}>
                <Button>Edit</Button>
              </a>,
            ]}
          >
            <List.Item.Meta
              title={s.data.name.iv}
              description={s.data.slug.iv}
            />
          </List.Item>
        )}
      />
      <Divider style={{ marginTop: 0 }} />
      <div className="pb-mx-3 pb-my-3">
        <Button type="primary" size="large" onClick={create}>
          Add Page
        </Button>
      </div>
    </div>
  )
}

const NewPage = ({ close, createPage }) => {
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [loading, setLoading] = useState(false)

  async function createNew(e) {
    e.preventDefault()
    setLoading(true)
    const { data } = await createContent("page", name, slug)
    console.log("NEW PAGE", data)
    createPage(data)
    setName("")
    setSlug("")
    setLoading(false)
    close()
  }

  return (
    <div>
      <SemanticForm onSubmit={createNew}>
        <SemanticForm.Input
          fluid
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <SemanticForm.Input
          fluid
          label="Slug"
          value={slug}
          onChange={e => setSlug(e.target.value)}
        />
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create new page
          </Button>
          <Button type="button" color="red" onClick={close} basic>
            Cancel
          </Button>
        </Space>
      </SemanticForm>
    </div>
  )
}

const SectionsOverlay = ({ open, setOpen }) => {
  const [level2, setLevel2] = useState(false)
  const [pages, setPages] = useState([])

  useEffect(() => {
    const asyncFct = async () => {
      setPages(await getPages())
    }
    asyncFct()
  }, [])

  const addPage = useCallback(newSection => {
    setPages([...pages, newSection])
  })

  return (
    <Drawer
      title="Pages"
      bodyStyle={{ padding: 0 }}
      width={480}
      visible={open}
      onClose={() => setOpen(false)}
      placement="left"
      zIndex={100000}
    >
      <PagesList pages={pages} create={() => setLevel2(true)} />

      <Drawer
        title="Add a page"
        placement="left"
        width={360}
        onClose={() => setLevel2(false)}
        zIndex={100001}
        visible={level2}
      >
        <NewPage createPage={addPage} close={() => setLevel2(false)} />
      </Drawer>
    </Drawer>
  )
}

export default SectionsOverlay
