export default [
  {
    type: "inline",
    properties: [
      {
        name: "padding-top",
        label: "Top",
        desc: "Sets the top padding of the element.",
        type: "unit",
      },
      {
        name: "padding-bottom",
        label: "Bottom",
        desc: "Sets the bottom padding of the element.",
        type: "unit",
      },
      {
        name: "padding-left",
        label: "Left",
        desc: "Sets the left padding of the element.",
        type: "unit",
      },
      {
        name: "padding-right",
        label: "Right",
        desc: "Sets the right padding of the element.",
        type: "unit",
      },
    ],
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        name: "padding",
        label: "padding",
        desc: "Sets the padding on all four sides of the element.",
        type: "string",
      },
    ],
  },
]
