export default [
  {
    type: "inline",
    properties: [
      {
        name: "height",
        label: "Height",
        desc: "Specify the height of an element.",
        type: "unit",
      },
      {
        name: "width",
        label: "Width",
        desc: "Specify the width of an element.",
        type: "unit",
      },
    ],
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        type: "inline",
        properties: [
          {
            name: "min-height",
            label: "Min Height",
            desc: "Specify the minimum height of an element.",
            type: "unit",
          },
          {
            name: "max-height",
            label: "Max Height",
            desc: "Specify the maximum height of an element.",
            type: "unit",
          },
        ],
      },
      {
        type: "inline",
        properties: [
          {
            name: "min-width",
            label: "Min width",
            desc: "Specify the minimum width of an element.",
            type: "unit",
          },
          {
            name: "max-width",
            label: "Max width",
            desc: "Specify the maximum width of an element.",
            type: "unit",
          },
        ],
      },
    ],
  },
]
