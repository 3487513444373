import React, { useState, useContext } from "react"
import { Button } from "antd"
import { SettingOutlined } from "@ant-design/icons"
import { PlusCircle, FileText, Layout } from "react-feather"
import { saveContent } from "../../scripts/server/pageContent"

import Sections from "./Sections"
import Pages from "./Pages"
import BlockSelector from "./BlockSelector"
import { StylesContext } from "../../Contexts/StylesContext"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

const EditorTopNav = () => {
  const [componentsState] = useContext(ComponentsContext)
  const [styleState] = useContext(StylesContext)
  const [publishing, setPublishing] = useState(false)
  const [openSections, setOpenSections] = useState(false)
  const [openPages, setOpenPages] = useState(false)

  const save = async () => {
    setPublishing(true)
    console.log("SAVE", componentsState, styleState.styles)

    await saveContent(componentsState, styleState)
    setPublishing(false)
  }

  console.log("HEADER RE-RENDER")

  return (
    <>
      <Pages open={openPages} setOpen={setOpenPages} />
      <Sections open={openSections} setOpen={setOpenSections} />

      <div className="pb-header">
        <div className="pb-header-section">
          <div className="pb-logo">PB</div>
          <BlockSelector selected="wrapper">
            <div className="pb-menu-item pb-ml-6">
              <PlusCircle className="pb-mr-1" />
              Blocks
            </div>
          </BlockSelector>

          <div
            className="pb-menu-item pb-ml-6"
            onClick={() => setOpenPages(!openPages)}
          >
            <FileText className="pb-mr-1" />
            Pages
          </div>

          <div
            className="pb-menu-item pb-ml-6"
            onClick={() => setOpenSections(!openSections)}
          >
            <Layout className="pb-mr-1" />
            Sections
          </div>
        </div>
        <div className="pb-header-section">
          <SettingOutlined className="pb-mr-3" />
          <Button
            size="large"
            type="primary"
            onClick={save}
            loading={publishing}
          >
            Publish
          </Button>
        </div>
      </div>
    </>
  )
}

export default EditorTopNav
