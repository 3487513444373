import React, { useReducer, useEffect } from "react"
import randomstring from "randomstring"
import { getPageOrSectionID } from "../scripts/server/pageContent"
import { localStore } from "../utils/customStorage"

export const StylesContext = React.createContext()

const initialState =
  localStore.getItem("sb-styles-state") &&
  localStore.getItem("sb-styles-id") === getPageOrSectionID()
    ? JSON.parse(localStore.getItem("sb-styles-state"))
    : {
        styles: {},
        classes: {},
      }

const reducer = (state, [type, payload]) => {
  switch (type) {
    case "SET_INITIAL_STATE":
      return {
        ...state,
        ...payload,
      }
    case "UPDATE_STYLE":
      return {
        ...state,
        classes: {
          ...state.classes,
          [payload.class]: payload.styles,
        },
      }

    default:
    /* throw new Error("Undefined Action"); */
  }
}

export const StylesContextProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (
      JSON.stringify(state) !== JSON.stringify(initialState) &&
      JSON.stringify(state) !== localStore.getItem("sb-styles-state")
    ) {
      if (localStore.getItem("sb-styles-state"))
        localStore.setItem(
          "sb-styles-last-modified",
          new Date(
            new Date() - new Date().getTimezoneOffset() * 60000
          ).toISOString()
        )
      localStore.setItem("sb-styles-id", getPageOrSectionID())
      localStore.setItem("sb-styles-state", JSON.stringify(state))
    }
  }, [state])

  return (
    <StylesContext.Provider value={[state, dispatch]}>
      {" "}
      {props.children}{" "}
    </StylesContext.Provider>
  )
}
