import React, { useState, useEffect, useCallback } from "react"
import { Form as SemanticForm } from "semantic-ui-react"
import {
  Drawer,
  Button,
  List,
  Space,
  Divider,
  Form,
  Input,
  Popover,
} from "antd"
import { createContent, getSections } from "../../scripts/server/pageContent"
import { WrapperComponent } from "../../Contexts/ComponentsContext"
import randomstring from "randomstring"

const SectionsList = ({ create, sections }) => {
  return (
    <div>
      <List
        itemLayout="horizontal"
        size="small"
        dataSource={sections}
        renderItem={s => (
          <List.Item
            key={s.id}
            actions={[
              <a key="edit" href={`/admin/editor?section=${s.id}`}>
                <Button>Edit</Button>
              </a>,
            ]}
          >
            <List.Item.Meta
              title={s.data.name.iv}
              description={s.data.slug.iv}
            />
          </List.Item>
        )}
      />
      <Divider style={{ marginTop: 0 }} />
      <div className="pb-mx-3 pb-my-3">
        <Button type="primary" size="large" onClick={create}>
          Add Page
        </Button>
      </div>
    </div>
  )
}

const NewSection = ({ close, addSection }) => {
  const [name, setName] = useState("")
  const [slug, setSlug] = useState("")
  const [loading, setLoading] = useState(false)

  async function createNew(e) {
    e.preventDefault()
    setLoading(true)

    const id = randomstring.generate({
      length: 6,
      charset: "alphabetic",
    })

    const wrapperId = `wrapper-${id}`

    let section = {
      [wrapperId]: {
        ...WrapperComponent,
        props: {
          id: wrapperId,
        },
      },
    }

    const { data } = await createContent("section", name, wrapperId, section)
    console.log("NEW SECTION", data)
    addSection(data)
    setName("")
    setSlug("")
    setLoading(false)
    close()
  }

  return (
    <div>
      <h2>Create a Section</h2>
      <SemanticForm onSubmit={createNew}>
        <SemanticForm.Input
          fluid
          label="Name"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <SemanticForm.Input
          fluid
          label="Slug"
          value={slug}
          onChange={e => setSlug(e.target.value)}
        />
        <Space>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create new page
          </Button>
          <Button type="button" color="red" onClick={close} basic>
            Cancel
          </Button>
        </Space>
      </SemanticForm>
    </div>
  )
}

const SectionsOverlay = ({ open, setOpen }) => {
  const [sections, setSections] = useState([])
  const [level2, setLevel2] = useState(false)

  useEffect(() => {
    const asyncFct = async () => {
      setSections(await getSections())
    }
    asyncFct()
  }, [])

  const addSection = useCallback(newSection => {
    setSections([...sections, newSection])
  })

  return (
    <Drawer
      title="Pages"
      bodyStyle={{ padding: 0 }}
      width={480}
      visible={open}
      onClose={() => setOpen(false)}
      placement="left"
      zIndex={100000}
    >
      <SectionsList sections={sections} create={() => setLevel2(true)} />

      <Drawer
        title="Add a page"
        placement="left"
        width={360}
        onClose={() => setLevel2(false)}
        zIndex={100001}
        visible={level2}
      >
        <NewSection addSection={addSection} close={() => setLevel2(false)} />
      </Drawer>
    </Drawer>
  )
}

export default SectionsOverlay
