import React, { useContext, useState, useCallback, useEffect } from "react"
import { EditorContext } from "../../Contexts/EditorContext"
import HoverState from "./HoverState"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

const HoverStateController = ({ canvasEl }) => {
  const [state, dispatch] = useContext(EditorContext)
  const [componentsState] = useContext(ComponentsContext)
  const [hoverState, setHoverState] = useState(null)

  const setStateOverlay = elemId => {
    const node = document.querySelector(`[data-sb-comp-id=${elemId}]`)
    if (elemId && elemId !== "wrapper" && node && !state.dragging) {
      const position = node.getBoundingClientRect()
      setHoverState({
        id: elemId,
        top: position.y,
        left: position.x,
        height: position.height,
        width: position.width,
      })
    }
  }

  // Select Element
  const mouseOver = useCallback(
    e => {
      //if (state.selected) return

      let node = e.target
      for (let elem of e.path) {
        if (elem.id === "canvas") {
          setHoverState(null)
          break
        }
        if (elem.getAttribute("data-sb-comp-id") !== null) {
          node = elem
          break
        }
      }

      setStateOverlay(node.getAttribute("data-sb-comp-id"))
    },
    [JSON.stringify(componentsState)]
  )

  useEffect(() => {
    if (canvasEl.current) {
      setTimeout(() => {
        canvasEl.current.addEventListener("mouseover", mouseOver)
      }, 200)

      return () => {
        canvasEl.current.removeEventListener("mouseover", mouseOver)
      }
    }
  }, [mouseOver])

  return (
    <>
      <HoverState position={hoverState} />
    </>
  )
}

export default HoverStateController
