import React, { useContext } from "react"
import { Button, Tag, Popconfirm } from "antd"
import {
  DeleteOutlined,
  EditOutlined,
  CopyOutlined,
  QuestionCircleOutlined,
  PauseOutlined,
} from "@ant-design/icons"
import { css, jsx } from "@emotion/core"
import classnames from "classnames"
import { EditorContext } from "../../Contexts/EditorContext"
import { useDrag } from "react-dnd"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

const HoverState = ({ position }) => {
  const [state, dispatch] = useContext(EditorContext)
  const [componentsState, componentsDispatch] = useContext(ComponentsContext)

  const [{ opacity }, drag, preview] = useDrag({
    item: { type: "block", id: position ? position.id : null },
    begin: () => {
      console.log("POSITION ID START", position.id)
      dispatch(["SET_DRAGGING", "move-block"])
    },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
        console.log("POSITION ID END", item.id)
        console.log(
          `Block ${item.id} was dropped on ${dropResult.id} at index ${dropResult.index}`
        )
        componentsDispatch([
          "MOVE_COMPONENT",
          {
            sourceId: item.id,
            targetId: dropResult.id,
            targetParentId: dropResult.parentId,
            index: dropResult.index,
          },
        ])
      }

      console.log("DROP ENDED", dropResult)
      dispatch(["SET_DRAGGING", null])
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  })

  if (!position || state.selected || state.dragging === "new-block") return null

  const c = componentsState[position.id]

  function duplicate() {
    componentsDispatch(["DUPLICATE", position.id])
  }

  function deleteComponent() {
    componentsDispatch(["DELETE_COMPONENT", position.id])
  }

  function select() {
    dispatch(["SELECT_COMPONENT", position.id])
  }

  const top = 124

  let style = css({
    top: Math.max(top, position.top),
    left: position.left,
    height: Math.min(
      position.top < top
        ? position.height - (top - position.top)
        : position.height,
      typeof window !== "undefined"
        ? window.innerHeight - (position.top > top ? position.top : top) - 36
        : 0
    ),
    width: position.width,
  })

  let moveStyle = css`
    cursor: move;
  `

  return (
    <div css={style} ref={preview} className="sb-hover-state">
      <Tag color="blue" className="sb-hover-tag">
        <b>{c && c.type}</b> #{position.id}
      </Tag>

      <div className="sb-tools-container">
        <div className="sb-tools" ref={drag} draggable="true">
          <Popconfirm
            title="Are you sure?"
            onConfirm={deleteComponent}
            placement="bottom"
            icon={<QuestionCircleOutlined style={{ color: "red" }} />}
          >
            <Button
              danger
              type="link"
              className="pb-ml-1"
              icon={<DeleteOutlined />}
            />
          </Popconfirm>

          <Button
            className="pb-ml-1"
            type="link"
            onClick={duplicate}
            icon={<CopyOutlined />}
          />

          <PauseOutlined className="pb-ml-1" css={moveStyle} rotate="90" />

          <Button
            className="pb-ml-1"
            type="link"
            onClick={select}
            icon={<EditOutlined />}
          />
        </div>
      </div>
    </div>
  )
}

export default HoverState
