export default [
  {
    name: "flex-direction",
    label: "flex-direction",
    desc: "	Specifies the direction of the flexible items.",
    type: "select",
    options: ["row", "row-reverse", "column", "column-reverse"],
  },
  {
    type: "inline",
    properties: [
      {
        name: "justify-content",
        label: "justify-content",
        desc:
          "Specifies how flex items are aligned along the main axis of the flex container after any flexible lengths and auto margins have been resolved.",
        type: "select",
        options: [
          "flex-start",
          "flex-end",
          "center",
          "space-between",
          "space-around",
        ],
      },
      {
        name: "align-items",
        label: "align-items",
        desc:
          "Specifies the default alignment for items within the flex container.",
        type: "select",
        options: ["flex-start", "flex-end", "center", "baseline", "stretch"],
      },
    ],
  },
  {
    name: "flex-wrap",
    label: "flex-wrap",
    desc: "	Specifies whether the flexible items should wrap or not.",
    type: "select",
    options: ["nowrap", "wrap", "wrap-reverse"],
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        name: "align-content",
        label: "align-content",
        desc:
          "Specifies the alignment of flexible container's items within the flex container.",
        type: "select",
        options: [
          "center",
          "flex-start",
          "flex-end",
          "space-between",
          "space-around",
          "stretch",
        ],
      },
      {
        name: "align-self",
        label: "align-self",
        desc:
          "Specifies the alignment for selected items within the flex container.",
        type: "select",
        options: [
          "auto",
          "flex-start",
          "flex-end",
          "center",
          "baseline",
          "stretch",
        ],
      },
      {
        name: "flex-basis",
        label: "flex-basis",
        desc: "	Specifies the initial main size of the flex item.",
        type: "text",
      },
      {
        name: "flex-shrink",
        label: "flex-shrink",
        desc:
          "Specifies how the flex item will shrink relative to the other items inside the flex container.",
        type: "text",
      },
      {
        name: "order",
        label: "order",
        desc:
          "Specifies the order in which a flex items are displayed and laid out within a flex container.",
        type: "text",
      },
      {
        name: "flex",
        label: "flex",
        desc: "Specifies the components of a flexible length.",
        type: "text",
      },
    ],
  },
]
