export default [
  {
    name: "font-family",
    label: "Font Family",
    desc: "Defines a list of fonts for element.",
    type: "text",
  },
  {
    type: "inline",
    properties: [
      {
        name: "font-size",
        label: "Size",
        desc: "Defines the font size for the text.",
        type: "unit",
      },
      {
        name: "font-weight",
        label: "Weight",
        desc: "Specify the font weight of the text.",
        type: "select",
        options: [
          "normal",
          "bold",
          "bolder",
          "lighter",
          "100",
          "200",
          "300",
          "400",
          "500",
          "600",
          "700",
          "800",
          "900",
        ],
      },
      {
        name: "font-style",
        label: "Style",
        desc: "Defines the font style for the text.",
        type: "select",
        options: ["normal", "italic", "oblique"],
      },
    ],
  },
  {
    name: "color",
    label: "Color",
    desc: "Specify the color of the text of an element.",
    type: "color",
  },
  {
    name: "text-align",
    label: "Text Align",
    desc: "Sets the horizontal alignment of inline content.",
    type: "select",
    options: ["left", "right", "center", "justify"],
  },
  {
    name: "line-height",
    label: "Line Height",
    desc: "Sets the height between lines of text.",
    type: "text",
  },
  {
    name: "Advanced",
    type: "section",
    active: false,
    border: false,
    properties: [
      {
        name: "font",
        label: "Font",
        desc: "Defines a variety of font properties within one declaration.",
        type: "string",
      },
      {
        name: "font-size-adjust",
        label: "Font Size Adjust",
        desc: "Preserves the readability of text when font fallback occurs.",
        type: "string",
      },
      {
        name: "font-stretch",
        label: "Font Stretch",
        desc: "Selects a normal, condensed, or expanded face from a font.",
        type: "select",
        options: [
          "normal",
          "ultra-condensed",
          "extra-condensed",
          "condensed",
          "semi-condensed",
          "semi-expanded",
          "expanded",
          "extra-expanded",
          "ultra-expanded",
        ],
      },
      {
        name: "font-variant",
        label: "Font Variant",
        desc: "Specify the font variant.",
        type: "select",
        options: ["normal", "small-caps"],
      },
      {
        name: "direction",
        label: "Direction",
        desc: "Define the text direction/writing direction.",
        type: "select",
        options: ["ltr", "rtl"],
      },
      {
        name: "tab-size",
        label: "Tab Size",
        desc: "Specifies the length of the tab character.",
        type: "text",
      },
    ],
  },
]
