import React, { useContext, useState, useEffect, useRef } from "react"
import {
  Divider,
  Form,
  Accordion,
  Select,
  Icon,
  Label,
  Search,
} from "semantic-ui-react"
import { AutoComplete } from "antd"
import { EditorContext } from "../../Contexts/EditorContext"
import stylesProperties from "../../definitions/stylesProperties"
import { SketchPicker } from "react-color"
import { css } from "glamor"
import { ComponentsContext } from "../../Contexts/ComponentsContext"
import { StylesContext } from "../../Contexts/StylesContext"
import { Alert } from "antd"
import { setStylesUtil, getStylesUtil } from "../../scripts/stylesUtils"

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+|\-)/g, function (match, index) {
    if (match === "-") return "" // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase()
  })
}

const renderFieldsTypes = (
  properties,
  styles,
  updateStyles,
  updateSelect,
  update
) => {
  if (Array.isArray(properties))
    return properties.map(property =>
      renderFieldsTypes(property, styles, updateStyles, updateSelect, update)
    )

  switch (properties.type) {
    case "section":
      return (
        <RenderSection
          properties={properties}
          styles={styles}
          updateStyles={updateStyles}
          updateSelect={updateSelect}
          update={update}
        />
      )
    case "inline":
      return (
        <RenderInline
          properties={properties}
          styles={styles}
          updateStyles={updateStyles}
          updateSelect={updateSelect}
          update={update}
        />
      )
    case "select":
      return (
        <RenderSelect
          property={properties}
          styles={styles}
          updateSelect={updateSelect}
        />
      )
    case "color":
      return (
        <RenderColor
          property={properties}
          styles={styles}
          updateStyles={updateStyles}
          update={update}
        />
      )
    default:
      return (
        <RenderString
          property={properties}
          styles={styles}
          updateStyles={updateStyles}
        />
      )
  }
}

const RenderSection = ({
  properties,
  styles,
  updateStyles,
  updateSelect,
  update,
}) => {
  const [active, setActive] = useState(properties.active)

  let rule = css({
    padding: properties.border ? 10 : 0,
    border: properties.border ? "1px solid #ddd" : "none",
    borderRadius: 4,
    marginBottom: 16,
  })

  return (
    <Accordion exclusive={false}>
      <Accordion.Title active={active} onClick={() => setActive(!active)}>
        <Icon name="dropdown" />
        {properties.name}
      </Accordion.Title>
      <Accordion.Content active={active}>
        <div className={rule}>
          {renderFieldsTypes(
            properties.properties,
            styles,
            updateStyles,
            updateSelect,
            update
          )}
        </div>
      </Accordion.Content>
    </Accordion>
  )
}

const RenderInline = ({
  properties,
  styles,
  updateStyles,
  updateSelect,
  update,
}) => {
  return (
    <Form.Group widths="equal">
      {renderFieldsTypes(
        properties.properties,
        styles,
        updateStyles,
        updateSelect,
        update
      )}
    </Form.Group>
  )
}

const RenderString = props => {
  const { name, label, desc } = props.property
  const camelCaseName = camelize(name)
  return (
    <Form.Input
      name={camelCaseName}
      label={label}
      value={props.styles[camelCaseName] || ""}
      onChange={props.updateStyles}
      size="small"
      fluid
    />
  )
}

const RenderSelect = props => {
  const { name, label, desc, options } = props.property
  const camelCaseName = camelize(name)
  return (
    <Form.Field
      control={Select}
      label={label}
      name={camelCaseName}
      value={props.styles[camelCaseName]}
      onChange={props.updateSelect}
      options={[
        { key: "remove", text: "remove style", value: "" },
        ...options.map(opt => ({ key: opt, text: opt, value: opt })),
        { key: "inherit", text: "inherit", value: "inherit" },
        { key: "initial", text: "initial", value: "initial" },
      ]}
      search
      size="small"
      fluid
    />
  )
}

const RenderColor = props => {
  const [show, toggle] = useState(false)

  const { name, label, desc } = props.property
  const camelCaseName = camelize(name)

  const colorChanged = (color, event) => {
    props.update(
      camelCaseName,
      color.rgb.a === 1
        ? color.hex
        : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`
    )
  }

  let color = props.styles[camelCaseName] || "#fff"

  let circle = css({
    color: color,
    opacity: "1 !important",
  })

  const picker = css({
    position: "absolute",
    zIndex: 10,
  })

  return (
    <div>
      <Form.Input
        icon={
          <Icon name="circle" {...circle} size="large">
            {" "}
          </Icon>
        }
        name={camelCaseName}
        label={label}
        value={props.styles[camelCaseName] || ""}
        onChange={props.updateStyles}
        size="small"
        onClick={() => toggle(!show)}
        fluid
      />

      {show && (
        <div className={picker}>
          <SketchPicker
            color={props.styles[camelCaseName]}
            onChange={colorChanged}
          />
        </div>
      )}
    </div>
  )
}

const StylePanel = () => {
  const [state, dispatch] = useContext(EditorContext)
  const [stylesState, stylesDispatch] = useContext(StylesContext)
  const [componentsState, componentsDispatch] = useContext(ComponentsContext)
  const [styles, setStyles] = useState({})
  const [initStyles, setInitStyles] = useState({})
  const [selected, setSelected] = useState(null)
  const [selectedClass, setSelectedClass] = useState("")

  const [tags, setTags] = useState(
    (state.selected && componentsState[state.selected].classNames) || []
  )
  const [suggestions, setSuggestions] = useState(
    Object.keys(stylesState.classes).map(value => ({ value }))
  )
  const [classSearchValue, setClassSearchValue] = useState("")
  const [classError, setClassError] = useState("")

  useEffect(() => {
    if (state.selected && selected !== state.selected) {
      setStyles({})
      setInitStyles({})
      setSelected(state.selected)
      setTags(componentsState[state.selected].classNames)
      setSelectedClass(state.selected)
    }
    if (state.selected) {
      console.log("SELECTED COMPONENT #1", componentsState[state.selected])
      setStyles(
        getStylesUtil(componentsState[state.selected].styles, state.screenSize)
      )
      setInitStyles(componentsState[state.selected].styles)
    }
  }, [state.selected, state.screenSize])

  useEffect(() => {
    console.log("RESET STYLES", selectedClass, state.selected)
    if (selectedClass && selectedClass !== state.selected) {
      setStyles(
        getStylesUtil(stylesState.classes[selectedClass], state.screenSize)
      )
      setInitStyles(stylesState.classes[selectedClass])
    } else if (state.selected && selectedClass === state.selected) {
      console.log("SELECTED COMPONENT #2", componentsState[state.selected])
      setStyles(
        getStylesUtil(componentsState[state.selected].styles, state.screenSize)
      )
      setInitStyles(componentsState[state.selected].styles)
    } else {
      setStyles({})
      setInitStyles({})
    }
  }, [selectedClass, tags, state.screenSize])

  useEffect(() => {
    if (componentsState[state.selected])
      setTags(componentsState[state.selected].classNames)
  }, [
    componentsState[state.selected],
    componentsState[state.selected] &&
      componentsState[state.selected].classNames,
  ])

  if (!state.selected) {
    return <div>Select an element to change it's style.</div>
  }

  const classDelete = className => {
    console.log("SET SELECTED", state.selected)
    setSelectedClass(state.selected)

    stylesDispatch([
      "DELETE_CLASSNAME",
      { id: state.selected, value: className },
    ])
  }

  const classAddition = value => {
    addClassToComponent(value)
  }

  const handleKeyPress = event => {
    console.log("KEY", event.key)
    if (event.key === "Enter" || event.key === ",") {
      console.log("enter press here! ")
      addClassToComponent(classSearchValue)
    }
  }

  const addClassToComponent = value => {
    value = value.replace(",", "")

    if (value[0] !== ".") {
      setClassError("This is not a class value.")
      return
    }

    if (tags.includes(value)) {
      setClassError("This class is already set.")
      return
    }

    setClassError(null)

    setClassSearchValue("")
    setTags([...tags, value])

    componentsDispatch(["ADD_CLASSNAME", { id: state.selected, value }])
  }

  const classDrag = (tag, currPos, newPos) => {
    const tags = [...tags]
    const newTags = tags.slice()

    newTags.splice(currPos, 1)
    newTags.splice(newPos, 0, tag)

    setTags(newTags)
  }

  const handleSearchChange = value => {
    setClassSearchValue(value)
  }

  const updateStyles = e => {
    console.log("New style", e.target.value, e.target.name)
    update(e.target.name, e.target.value)
  }

  const updateSelect = (_elem, { name, value }) => {
    console.log("Select", name, value)
    update(name, value)
  }

  const update = (name, value) => {
    let newStyle = setStylesUtil(initStyles, name, value, state.screenSize)
    console.log("NEW STYLE!!!", newStyle)
    setStyles(getStylesUtil(newStyle, state.screenSize))
    setInitStyles(newStyle)
    if (selectedClass === state.selected) {
      componentsDispatch([
        "UPDATE_STYLE",
        {
          styles: newStyle,
          class: selectedClass,
        },
      ])
    } else {
      stylesDispatch([
        "UPDATE_STYLE",
        {
          styles: newStyle,
          class: selectedClass,
        },
      ])
    }
  }

  let rule = css({
    overflowY: "auto",
    overflowX: "hidden",
  })

  if (!styles) return null

  console.log("SUGGESTIONS", suggestions)

  return (
    <div {...rule}>
      <form>
        {state.screenSize === "tablet" && (
          <Alert
            message="You changes currently only affect screen size of 768px (tablet width) or smaller."
            type="info"
            showIcon
          />
        )}
        {state.screenSize === "mobile" && (
          <Alert
            message="You changes currently only affect screen size of 360px (mobile width) or smaller."
            type="info"
            showIcon
          />
        )}

        <div className="pb-my-2">
          <Label
            color={selectedClass === state.selected ? "blue" : "grey"}
            onClick={() => setSelectedClass(state.selected)}
          >
            #{state.selected}
          </Label>
          {tags.map((className, index) => (
            <Label
              key={index}
              color={selectedClass === className ? "blue" : "grey"}
            >
              <span onClick={() => setSelectedClass(className)}>
                {className}
              </span>{" "}
              <Icon
                name="delete"
                onClick={e => {
                  e.preventDefault()
                  classDelete(className)
                }}
              />
            </Label>
          ))}
        </div>

        <div className="pb-mb-2">
          {classError && (
            <Label basic color="red" pointing="below">
              {classError}
            </Label>
          )}

          <AutoComplete
            value={classSearchValue}
            options={suggestions}
            onSelect={classAddition}
            onSearch={handleSearchChange}
            onKeyUp={handleKeyPress}
            placeholder="Select or create a class"
            filterOption={true}
            style={{ width: 200 }}
          />
        </div>

        {renderFieldsTypes(
          stylesProperties,
          styles,
          updateStyles,
          updateSelect,
          update
        )}
      </form>
    </div>
  )
}

export default StylePanel
