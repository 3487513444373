import React, { useContext, useState, useEffect, useRef } from "react"
import { List } from "semantic-ui-react"
import { useDrag, useDrop } from "react-dnd"

import { EditorContext } from "../../Contexts/EditorContext"
import { css } from "glamor"
import { getComponentsMap } from "../../components/index"
import { ComponentsContext } from "../../Contexts/ComponentsContext"

const compsMap = getComponentsMap()

function toComponentsList(components, list) {
  const newList = list.map(id => ({
    id: components[id].props.id,
    oldParentId: components[id].parentId,
  }))

  return newList
}

const Card = ({ id, select, setStateOverlay }) => {
  const [state] = useContext(EditorContext)
  const [componentsState] = useContext(ComponentsContext)

  const component = componentsState[id]
  const isSelected = state.selected === id

  if (!component) return null

  let selectedColor = css({
    color: isSelected ? "#1f7fc8 !important" : undefined,
  })

  let rule = css({
    cursor: "pointer",
  })

  return (
    <div role="listitem" className="item" {...rule} key={id}>
      <div className="sb-layer-content">
        <List.Icon size="small" id={`layer-${id}`} {...selectedColor}>
          {compsMap.get(component.type) &&
            compsMap.get(component.type).block.icon}
        </List.Icon>
        <div className="content">
          <List.Header {...selectedColor} onClick={() => select(id)}>
            #{id}
          </List.Header>
          <List.Description {...selectedColor} onClick={() => select(id)}>
            {component.type}
          </List.Description>
        </div>
      </div>

      {component.components.length > 0 && (
        <SortableList
          currentId={id}
          components={component.components}
          select={select}
          setStateOverlay={setStateOverlay}
        />
      )}
    </div>
  )
}

const SortableList = ({ components, select, currentId, setStateOverlay }) => {
  const [componentsState] = useContext(ComponentsContext)

  const [cards, setCards] = useState(
    toComponentsList(componentsState, components)
  )

  const [, drop] = useDrop({ accept: "card" })

  useEffect(() => {
    setCards(toComponentsList(componentsState, components))
  }, [JSON.stringify(componentsState)])

  // console.log("COMPONENTS LIST", cards)

  return (
    <div className="ui list" role="list" ref={drop}>
      {cards.map(card => (
        <Card
          key={card.id}
          id={`${card.id}`}
          select={select}
          setStateOverlay={setStateOverlay}
        />
      ))}
    </div>
  )
}

const LayersPanel = props => {
  const [state, dispatch] = useContext(EditorContext)
  const [componentsState] = useContext(ComponentsContext)

  // console.log("LAYERS WRAPPER", props.wrapper, componentsState, componentsState[props.wrapper])

  useEffect(() => {
    if (state.selected) {
      var element = document.getElementById(`layer-${state.selected}`)
      if (element)
        element.scrollIntoView({ behavior: "smooth", block: "center" })
    }
  }, [state.selected])

  if (!componentsState || !componentsState[props.wrapper]) return null

  const select = id => {
    dispatch(["SELECT_COMPONENT", id])
  }

  return (
    <div className="pb-layers">
      <SortableList
        components={componentsState[props.wrapper].components}
        currentId={props.wrapper}
        select={select}
        setStateOverlay={props.setStateOverlay}
      />
    </div>
  )
}

export default LayersPanel
